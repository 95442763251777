.m-0 {
    margin: 0!important
}

.mt-0,
.my-0 {
    margin-top: 0!important
}

.mr-0,
.mx-0 {
    margin-right: 0!important
}

.mb-0,
.my-0 {
    margin-bottom: 0!important
}

.ml-0,
.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,
.my-1 {
    margin-top: .25rem!important
}

.mr-1,
.mx-1 {
    margin-right: .25rem!important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,
.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,
.my-2 {
    margin-top: .5rem!important
}

.mr-2,
.mx-2 {
    margin-right: .5rem!important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,
.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,
.my-3 {
    margin-top: 1rem!important
}

.mr-3,
.mx-3 {
    margin-right: 1rem!important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,
.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem!important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem!important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,
.my-5 {
    margin-top: 3rem!important
}

.mr-5,
.mx-5 {
    margin-right: 3rem!important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,
.mx-5 {
    margin-left: 3rem!important
}

.p-0 {
    padding: 0!important
}

.pt-0,
.py-0 {
    padding-top: 0!important
}

.pr-0,
.px-0 {
    padding-right: 0!important
}

.pb-0,
.py-0 {
    padding-bottom: 0!important
}

.pl-0,
.px-0 {
    padding-left: 0!important
}

.p-1 {
    padding: .25rem!important
}

.pt-1,
.py-1 {
    padding-top: .25rem!important
}

.pr-1,
.px-1 {
    padding-right: .25rem!important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem!important
}

.pl-1,
.px-1 {
    padding-left: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.pt-2,
.py-2 {
    padding-top: .5rem!important
}

.pr-2,
.px-2 {
    padding-right: .5rem!important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem!important
}

.pl-2,
.px-2 {
    padding-left: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.pt-3,
.py-3 {
    padding-top: 1rem!important
}

.pr-3,
.px-3 {
    padding-right: 1rem!important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem!important
}

.pl-3,
.px-3 {
    padding-left: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem!important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem!important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem!important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.pt-5,
.py-5 {
    padding-top: 3rem!important
}

.pr-5,
.px-5 {
    padding-right: 3rem!important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem!important
}

.pl-5,
.px-5 {
    padding-left: 3rem!important
}

@media (max-width: 766px) {
    .mm-0 {
        margin: 0!important
    }
    .mmt-0,
    .mmy-0 {
        margin-top: 0!important
    }
    .mmr-0,
    .mmx-0 {
        margin-right: 0!important
    }
    .mmb-0,
    .mmy-0 {
        margin-bottom: 0!important
    }
    .mml-0,
    .mmx-0 {
        margin-left: 0!important
    }
    .mm-1 {
        margin: .25rem!important
    }
    .mmt-1,
    .mmy-1 {
        margin-top: .25rem!important
    }
    .mmr-1,
    .mmx-1 {
        margin-right: .25rem!important
    }
    .mmb-1,
    .mmy-1 {
        margin-bottom: .25rem!important
    }
    .mml-1,
    .mmx-1 {
        margin-left: .25rem!important
    }
    .mm-2 {
        margin: .5rem!important
    }
    .mmt-2,
    .mmy-2 {
        margin-top: .5rem!important
    }
    .mmr-2,
    .mmx-2 {
        margin-right: .5rem!important
    }
    .mmb-2,
    .mmy-2 {
        margin-bottom: .5rem!important
    }
    .mml-2,
    .mmx-2 {
        margin-left: .5rem!important
    }
    .mm-3 {
        margin: 1rem!important
    }
    .mmt-3,
    .mmy-3 {
        margin-top: 1rem!important
    }
    .mmr-3,
    .mmx-3 {
        margin-right: 1rem!important
    }
    .mmb-3,
    .mmy-3 {
        margin-bottom: 1rem!important
    }
    .mml-3,
    .mmx-3 {
        margin-left: 1rem!important
    }
    .mm-4 {
        margin: 1.5rem!important
    }
    .mmt-4,
    .mmy-4 {
        margin-top: 1.5rem!important
    }
    .mmr-4,
    .mmx-4 {
        margin-right: 1.5rem!important
    }
    .mmb-4,
    .mmy-4 {
        margin-bottom: 1.5rem!important
    }
    .mml-4,
    .mmx-4 {
        margin-left: 1.5rem!important
    }
    .mm-5 {
        margin: 3rem!important
    }
    .mmt-5,
    .mmy-5 {
        margin-top: 3rem!important
    }
    .mmr-5,
    .mmx-5 {
        margin-right: 3rem!important
    }
    .mmb-5,
    .mmy-5 {
        margin-bottom: 3rem!important
    }
    .mml-5,
    .mmx-5 {
        margin-left: 3rem!important
    }
    .mp-0 {
        padding: 0!important
    }
    .mpt-0,
    .mpy-0 {
        padding-top: 0!important
    }
    .mpr-0,
    .mpx-0 {
        padding-right: 0!important
    }
    .mpb-0,
    .mpy-0 {
        padding-bottom: 0!important
    }
    .mpl-0,
    .mpx-0 {
        padding-left: 0!important
    }
    .mp-1 {
        padding: .25rem!important
    }
    .mpt-1,
    .mpy-1 {
        padding-top: .25rem!important
    }
    .mpr-1,
    .mpx-1 {
        padding-right: .25rem!important
    }
    .mpb-1,
    .mpy-1 {
        padding-bottom: .25rem!important
    }
    .mpl-1,
    .mpx-1 {
        padding-left: .25rem!important
    }
    .mp-2 {
        padding: .5rem!important
    }
    .mpt-2,
    .mpy-2 {
        padding-top: .5rem!important
    }
    .mpr-2,
    .mpx-2 {
        padding-right: .5rem!important
    }
    .mpb-2,
    .mpy-2 {
        padding-bottom: .5rem!important
    }
    .mpl-2,
    .mpx-2 {
        padding-left: .5rem!important
    }
    .mp-3 {
        padding: 1rem!important
    }
    .mpt-3,
    .mpy-3 {
        padding-top: 1rem!important
    }
    .mpr-3,
    .mpx-3 {
        padding-right: 1rem!important
    }
    .mpb-3,
    .mpy-3 {
        padding-bottom: 1rem!important
    }
    .mpl-3,
    .mpx-3 {
        padding-left: 1rem!important
    }
    .mp-4 {
        padding: 1.5rem!important
    }
    .mpt-4,
    .mpy-4 {
        padding-top: 1.5rem!important
    }
    .mpr-4,
    .mpx-4 {
        padding-right: 1.5rem!important
    }
    .mpb-4,
    .mpy-4 {
        padding-bottom: 1.5rem!important
    }
    .mpl-4,
    .mpx-4 {
        padding-left: 1.5rem!important
    }
    .mp-5 {
        padding: 3rem!important
    }
    .mpt-5,
    .mpy-5 {
        padding-top: 3rem!important
    }
    .mpr-5,
    .mpx-5 {
        padding-right: 3rem!important
    }
    .mpb-5,
    .mpy-5 {
        padding-bottom: 3rem!important
    }
    .mpl-5,
    .mpx-5 {
        padding-left: 3rem!important
    }
}

@media (min-width: 767px) {
    .dm-0 {
        margin: 0!important
    }
    .dmt-0,
    .dmy-0 {
        margin-top: 0!important
    }
    .dmr-0,
    .dmx-0 {
        margin-right: 0!important
    }
    .dmb-0,
    .dmy-0 {
        margin-bottom: 0!important
    }
    .dml-0,
    .dmx-0 {
        margin-left: 0!important
    }
    .dm-1 {
        margin: .25rem!important
    }
    .dmt-1,
    .dmy-1 {
        margin-top: .25rem!important
    }
    .dmr-1,
    .dmx-1 {
        margin-right: .25rem!important
    }
    .dmb-1,
    .dmy-1 {
        margin-bottom: .25rem!important
    }
    .dml-1,
    .dmx-1 {
        margin-left: .25rem!important
    }
    .dm-2 {
        margin: .5rem!important
    }
    .dmt-2,
    .dmy-2 {
        margin-top: .5rem!important
    }
    .dmr-2,
    .dmx-2 {
        margin-right: .5rem!important
    }
    .dmb-2,
    .dmy-2 {
        margin-bottom: .5rem!important
    }
    .dml-2,
    .dmx-2 {
        margin-left: .5rem!important
    }
    .dm-3 {
        margin: 1rem!important
    }
    .dmt-3,
    .dmy-3 {
        margin-top: 1rem!important
    }
    .dmr-3,
    .dmx-3 {
        margin-right: 1rem!important
    }
    .dmb-3,
    .dmy-3 {
        margin-bottom: 1rem!important
    }
    .dml-3,
    .dmx-3 {
        margin-left: 1rem!important
    }
    .dm-4 {
        margin: 1.5rem!important
    }
    .dmt-4,
    .dmy-4 {
        margin-top: 1.5rem!important
    }
    .dmr-4,
    .dmx-4 {
        margin-right: 1.5rem!important
    }
    .dmb-4,
    .dmy-4 {
        margin-bottom: 1.5rem!important
    }
    .dml-4,
    .dmx-4 {
        margin-left: 1.5rem!important
    }
    .dm-5 {
        margin: 3rem!important
    }
    .dmt-5,
    .dmy-5 {
        margin-top: 3rem!important
    }
    .dmr-5,
    .dmx-5 {
        margin-right: 3rem!important
    }
    .dmb-5,
    .dmy-5 {
        margin-bottom: 3rem!important
    }
    .dml-5,
    .dmx-5 {
        margin-left: 3rem!important
    }
    .dp-0 {
        padding: 0!important
    }
    .dpt-0,
    .dpy-0 {
        padding-top: 0!important
    }
    .dpr-0,
    .dpx-0 {
        padding-right: 0!important
    }
    .dpb-0,
    .dpy-0 {
        padding-bottom: 0!important
    }
    .dpl-0,
    .dpx-0 {
        padding-left: 0!important
    }
    .dp-1 {
        padding: .25rem!important
    }
    .dpt-1,
    .dpy-1 {
        padding-top: .25rem!important
    }
    .dpr-1,
    .dpx-1 {
        padding-right: .25rem!important
    }
    .dpb-1,
    .dpy-1 {
        padding-bottom: .25rem!important
    }
    .dpl-1,
    .dpx-1 {
        padding-left: .25rem!important
    }
    .dp-2 {
        padding: .5rem!important
    }
    .dpt-2,
    .dpy-2 {
        padding-top: .5rem!important
    }
    .dpr-2,
    .dpx-2 {
        padding-right: .5rem!important
    }
    .dpb-2,
    .dpy-2 {
        padding-bottom: .5rem!important
    }
    .dpl-2,
    .dpx-2 {
        padding-left: .5rem!important
    }
    .dp-3 {
        padding: 1rem!important
    }
    .dpt-3,
    .dpy-3 {
        padding-top: 1rem!important
    }
    .dpr-3,
    .dpx-3 {
        padding-right: 1rem!important
    }
    .dpb-3,
    .dpy-3 {
        padding-bottom: 1rem!important
    }
    .dpl-3,
    .dpx-3 {
        padding-left: 1rem!important
    }
    .dp-4 {
        padding: 1.5rem!important
    }
    .dpt-4,
    .dpy-4 {
        padding-top: 1.5rem!important
    }
    .dpr-4,
    .dpx-4 {
        padding-right: 1.5rem!important
    }
    .dpb-4,
    .dpy-4 {
        padding-bottom: 1.5rem!important
    }
    .dpl-4,
    .dpx-4 {
        padding-left: 1.5rem!important
    }
    .dp-5 {
        padding: 3rem!important
    }
    .dpt-5,
    .dpy-5 {
        padding-top: 3rem!important
    }
    .dpr-5,
    .dpx-5 {
        padding-right: 3rem!important
    }
    .dpb-5,
    .dpy-5 {
        padding-bottom: 3rem!important
    }
    .dpl-5,
    .dpx-5 {
        padding-left: 3rem!important
    }
}